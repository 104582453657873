import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';
import { BannerComponent } from './components/banner/banner.component';
import { ExternalComponent } from './components/gpms/external/external.component';
import { ExitComponent } from './components/exit/exit.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpInterceptorService } from './services/http-interceptor.service.';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import * as i0 from "@angular/core";
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    AppModule.ɵmod = i0.ɵɵdefineNgModule({ type: AppModule, bootstrap: [AppComponent] });
    AppModule.ɵinj = i0.ɵɵdefineInjector({ factory: function AppModule_Factory(t) { return new (t || AppModule)(); }, providers: [
            AuthGuardService,
            AuthenticationService,
            UserService,
            JwtHelperService,
            { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
            {
                provide: LocationStrategy,
                useClass: HashLocationStrategy
            }
        ], imports: [[
                BrowserModule,
                AppRoutingModule,
                HttpClientModule
            ]] });
    return AppModule;
}());
export { AppModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AppModule, { declarations: [AppComponent,
        HeaderComponent,
        FooterComponent,
        MenuComponent,
        BannerComponent,
        ExternalComponent,
        ExitComponent,
        NotFoundComponent], imports: [BrowserModule,
        AppRoutingModule,
        HttpClientModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppModule, [{
        type: NgModule,
        args: [{
                declarations: [
                    AppComponent,
                    HeaderComponent,
                    FooterComponent,
                    MenuComponent,
                    BannerComponent,
                    ExternalComponent,
                    ExitComponent,
                    NotFoundComponent
                ],
                imports: [
                    BrowserModule,
                    AppRoutingModule,
                    HttpClientModule
                ],
                providers: [
                    AuthGuardService,
                    AuthenticationService,
                    UserService,
                    JwtHelperService,
                    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
                    {
                        provide: LocationStrategy,
                        useClass: HashLocationStrategy
                    }
                ],
                bootstrap: [AppComponent]
            }]
    }], null, null); })();

import { Component } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "src/app/services/user.service";
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(usrService) {
        this.usrService = usrService;
        this.usr = usrService.getUser();
    }
    HeaderComponent.ɵfac = function HeaderComponent_Factory(t) { return new (t || HeaderComponent)(i0.ɵɵdirectiveInject(i1.UserService)); };
    HeaderComponent.ɵcmp = i0.ɵɵdefineComponent({ type: HeaderComponent, selectors: [["app-header"]], decls: 5, vars: 2, consts: [[1, "user-holder"], [1, "user"], [1, "user", 2, "float", "right"]], template: function HeaderComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵelementStart(1, "span", 1);
            i0.ɵɵtext(2);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "span", 2);
            i0.ɵɵtext(4);
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate1("Logged in as : ", ctx.usr.user, "");
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate(ctx.usr.locale);
        } }, styles: [".user[_ngcontent-%COMP%]{display:inline-block}"] });
    return HeaderComponent;
}());
export { HeaderComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(HeaderComponent, [{
        type: Component,
        args: [{
                selector: 'app-header',
                templateUrl: './header.component.html',
                styleUrls: ['./header.component.scss']
            }]
    }], function () { return [{ type: i1.UserService }]; }, null); })();

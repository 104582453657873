import { Component, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RoleConst } from 'src/app/role.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "src/app/services/user.service";
import * as i3 from "@angular/common/http";
import * as i4 from "src/app/services/authentication.service";
import * as i5 from "@angular/common";
import * as i6 from "../header/header.component";
import * as i7 from "../banner/banner.component";
import * as i8 from "../footer/footer.component";
var _c0 = ["mainMenu"];
function MenuComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r0.loadingMessage);
} }
var _c1 = function (a0) { return { "choices-main-disable": a0 }; };
function MenuComponent_li_50_Template(rf, ctx) { if (rf & 1) {
    var _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 6);
    i0.ɵɵlistener("click", function MenuComponent_li_50_Template_li_click_0_listener() { i0.ɵɵrestoreView(_r4); var ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.NavigateTo("R"); });
    i0.ɵɵelementStart(1, "span", 7);
    i0.ɵɵtext(2, "R.");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3, " Report View");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c1, !ctx_r2.hasREPORTVIEW));
} }
var _c2 = function () { return ["/logout"]; };
var MenuComponent = /** @class */ (function () {
    function MenuComponent(router, usrService, http, auth, renderer) {
        this.router = router;
        this.usrService = usrService;
        this.http = http;
        this.auth = auth;
        this.renderer = renderer;
        this.loadingIndicator = true;
        this.loadingMessage = 'Loading...';
        this.racfGroup = [];
        this.hasMEADADMN = false;
        this.hasMEADSIAM = false;
        this.hasMMTRNG = false;
        this.hasSTNSADMN = false;
        this.hasREPORTVIEW = false;
        this.hasSTNMANAGER = false;
        this.showReportView = true;
    }
    MenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        var main = this;
        var httpOptions = {
            withCredentials: true
        };
        var email = this.usrService.getUser().email;
        var authCheckUrl = environment.auth_service + 'api/';
        this.http.get(authCheckUrl + 'authUsers?email=' + email, httpOptions)
            .subscribe(function (data) {
            if (data) {
                data.forEach(function (e) {
                    _this.racfGroup.push(e.racfGroup);
                    if (RoleConst.SUPERADMIN === e.racfGroup) {
                        _this.hasMEADADMN = true;
                        _this.hasMEADSIAM = true;
                        _this.hasMMTRNG = true;
                        _this.hasSTNSADMN = true;
                        _this.hasREPORTVIEW = true;
                    }
                    else if (RoleConst.MEADADMN === e.racfGroup) {
                        _this.hasMEADADMN = true;
                        _this.hasREPORTVIEW = true;
                    }
                    else if (RoleConst.MEADSIAM === e.racfGroup) {
                        _this.hasMMTRNG = true;
                        _this.hasMEADSIAM = true;
                        _this.hasREPORTVIEW = true;
                    }
                    else if (RoleConst.MMTRNG === e.racfGroup) {
                        _this.hasMMTRNG = true;
                        _this.hasREPORTVIEW = true;
                    }
                    else if (RoleConst.STNSADMN === e.racfGroup) {
                        _this.hasSTNSADMN = true;
                        _this.hasREPORTVIEW = true;
                    }
                    else if (RoleConst.STNMANAGER === e.racfGroup) {
                        _this.hasSTNMANAGER = true;
                        // this.hasREPORTVIEW = true;
                    }
                });
                if (_this.racfGroup.length === 0) {
                    _this.router.navigate(['unauthorized'], { skipLocationChange: true });
                    return;
                }
            }
            setTimeout(function () {
                main.loadingIndicator = false;
                main.renderer.setStyle(main.mainMenu.nativeElement, 'visibility', 'visible');
            }, 0);
        });
        this.http.get(authCheckUrl + 'gpmsId?email=' + email, httpOptions)
            .subscribe(function (data) {
            if (data) {
                _this.gpmsId = data.gpmsId;
            }
        });
    };
    MenuComponent.prototype.ngAfterViewInit = function () {
        setTimeout(function () { return document.querySelector("input").focus(); }, 500);
    };
    MenuComponent.prototype.NavigateTo = function (NavKey) {
        this.auth.clearUserActivity();
        switch (NavKey) {
            case "1":
                if (this.hasSTNSADMN) {
                    this.router.navigate(['gpms', 'femn', this.gpmsId], { skipLocationChange: true });
                }
                break;
            case "2":
                if (this.hasMEADADMN) {
                    this.router.navigate(['gpms', 'msmn', this.gpmsId], { skipLocationChange: true });
                }
                break;
            case "3":
                if (this.hasMEADADMN) {
                    this.router.navigate(['gpms', 'maint_admin', this.gpmsId], { skipLocationChange: true });
                }
                break;
            case "4":
                if (this.hasMEADSIAM) {
                    this.router.navigate(['gpms', 'mst0', this.gpmsId], { skipLocationChange: true });
                }
                break;
            case '5':
                if (this.hasMEADSIAM) {
                    this.router.navigate(['gpms', 'msot', this.gpmsId], { skipLocationChange: true });
                }
                break;
            case '6':
                if (this.hasMMTRNG) {
                    this.router.navigate(['gpms', 'msdi', this.gpmsId], { skipLocationChange: true });
                }
                break;
            case '7':
                if (this.hasMEADADMN) {
                    this.router.navigate(['gpms', 'datAdminWeb', this.gpmsId], { skipLocationChange: true });
                }
                break;
            case '8':
                if (this.hasSTNMANAGER) {
                    this.router.navigate(['gpms', 'datAdminWeb', this.gpmsId], { skipLocationChange: true });
                }
                break;
            case 'R':
                if (this.hasREPORTVIEW) {
                    this.openReportView();
                }
                break;
            case "L":
                this.router.navigate(['/logout']);
                break;
        }
        return;
    };
    MenuComponent.prototype.openReportView = function () {
        window.open(environment.reportView + '?id=' + this.gpmsId.toLowerCase() + '&racf='
            + this.getMainRacfGroup() + '&token=' + this.usrService.getUser().accessToken, '_blank');
    };
    MenuComponent.prototype.getMainRacfGroup = function () {
        if (this.racfGroup.indexOf(RoleConst.SUPERADMIN) !== -1) {
            return RoleConst.SUPERADMIN;
        }
        if (this.racfGroup.indexOf(RoleConst.MEADADMN) !== -1) {
            return RoleConst.MEADADMN;
        }
        if (this.racfGroup.indexOf(RoleConst.STNSADMN) !== -1) {
            return RoleConst.STNSADMN;
        }
        if (this.racfGroup.indexOf(RoleConst.MEADSIAM) !== -1) {
            return RoleConst.MEADSIAM;
        }
        return RoleConst.MMTRNG;
    };
    MenuComponent.ɵfac = function MenuComponent_Factory(t) { return new (t || MenuComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.UserService), i0.ɵɵdirectiveInject(i3.HttpClient), i0.ɵɵdirectiveInject(i4.AuthenticationService), i0.ɵɵdirectiveInject(i0.Renderer2)); };
    MenuComponent.ɵcmp = i0.ɵɵdefineComponent({ type: MenuComponent, selectors: [["app-menu"]], viewQuery: function MenuComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(_c0, true);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.mainMenu = _t.first);
        } }, decls: 60, vars: 28, consts: [["class", "loading-indicator", 4, "ngIf"], [1, "mf-holder"], ["mainMenu", ""], [1, "col-lg-12", "no-padd", "main-title"], [1, "col-lg-12", "no-padd"], [1, "line", "columns"], [1, "col3", "choices-main", 3, "ngClass", "click"], [1, "menu-num"], ["class", "col3 choices-main", 3, "ngClass", "click", 4, "ngIf"], [1, "col3", "choices-main", 3, "routerLink"], [1, "col3"], ["type", "text", "maxlength", "2", "oninput", "this.value = this.value.toUpperCase()", 1, "mf-input", 3, "keydown.enter"], [1, "loading-indicator"]], template: function MenuComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, MenuComponent_div_0_Template, 2, 1, "div", 0);
            i0.ɵɵelementStart(1, "div", 1, 2);
            i0.ɵɵelement(3, "app-header");
            i0.ɵɵelementStart(4, "div", 3);
            i0.ɵɵelementStart(5, "span");
            i0.ɵɵtext(6, "\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(7, "br");
            i0.ɵɵelementStart(8, "span");
            i0.ɵɵtext(9, "GPMS HOME MENU");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(10, "br");
            i0.ɵɵelementStart(11, "span");
            i0.ɵɵtext(12, "\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550\u2550");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(13, "br");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(14, "app-banner");
            i0.ɵɵelementStart(15, "div", 4);
            i0.ɵɵelement(16, "span", 5);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(17, "ul", 4);
            i0.ɵɵelementStart(18, "li", 6);
            i0.ɵɵlistener("click", function MenuComponent_Template_li_click_18_listener() { return ctx.NavigateTo("1"); });
            i0.ɵɵelementStart(19, "span", 7);
            i0.ɵɵtext(20, "1.");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(21, " GPMS DB2");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(22, "li", 6);
            i0.ɵɵlistener("click", function MenuComponent_Template_li_click_22_listener() { return ctx.NavigateTo("2"); });
            i0.ɵɵelementStart(23, "span", 7);
            i0.ɵɵtext(24, "2.");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(25, " GPMS-MF");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(26, "li", 6);
            i0.ɵɵlistener("click", function MenuComponent_Template_li_click_26_listener() { return ctx.NavigateTo("3"); });
            i0.ɵɵelementStart(27, "span", 7);
            i0.ɵɵtext(28, "3.");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(29, " TSO - Maintenance Administration");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(30, "li", 6);
            i0.ɵɵlistener("click", function MenuComponent_Template_li_click_30_listener() { return ctx.NavigateTo("4"); });
            i0.ɵɵelementStart(31, "span", 7);
            i0.ɵɵtext(32, "4.");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(33, " MST0 - USAir Training");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(34, "li", 6);
            i0.ɵɵlistener("click", function MenuComponent_Template_li_click_34_listener() { return ctx.NavigateTo("5"); });
            i0.ɵɵelementStart(35, "span", 7);
            i0.ɵɵtext(36, "5.");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(37, " MSOT - Overtime Tracking");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(38, "li", 6);
            i0.ɵɵlistener("click", function MenuComponent_Template_li_click_38_listener() { return ctx.NavigateTo("6"); });
            i0.ɵɵelementStart(39, "span", 7);
            i0.ɵɵtext(40, "6.");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(41, " MSDI - Employee Training");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(42, "li", 6);
            i0.ɵɵlistener("click", function MenuComponent_Template_li_click_42_listener() { return ctx.NavigateTo("7"); });
            i0.ɵɵelementStart(43, "span", 7);
            i0.ɵɵtext(44, "7.");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(45, " DAT Admin");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(46, "li", 6);
            i0.ɵɵlistener("click", function MenuComponent_Template_li_click_46_listener() { return ctx.NavigateTo("8"); });
            i0.ɵɵelementStart(47, "span", 7);
            i0.ɵɵtext(48, "8.");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(49, " Station Manager");
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(50, MenuComponent_li_50_Template, 4, 3, "li", 8);
            i0.ɵɵelementStart(51, "li", 9);
            i0.ɵɵelementStart(52, "span", 7);
            i0.ɵɵtext(53, "L.");
            i0.ɵɵelementEnd();
            i0.ɵɵtext(54, " Logout");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(55, "div", 4);
            i0.ɵɵelementStart(56, "span", 10);
            i0.ɵɵtext(57, "Select and press ENTER : ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(58, "input", 11);
            i0.ɵɵlistener("keydown.enter", function MenuComponent_Template_input_keydown_enter_58_listener($event) { return ctx.NavigateTo($event.target.value); });
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
            i0.ɵɵelement(59, "app-footer");
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", ctx.loadingIndicator);
            i0.ɵɵadvance(18);
            i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(11, _c1, !ctx.hasSTNSADMN));
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(13, _c1, !ctx.hasMEADADMN));
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(15, _c1, !ctx.hasMEADADMN));
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(17, _c1, !ctx.hasMEADSIAM));
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(19, _c1, !ctx.hasMEADSIAM));
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(21, _c1, !ctx.hasMMTRNG));
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(23, _c1, !ctx.hasMEADADMN));
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(25, _c1, !ctx.hasSTNMANAGER));
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngIf", ctx.showReportView);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction0(27, _c2));
        } }, directives: [i5.NgIf, i6.HeaderComponent, i7.BannerComponent, i5.NgClass, i1.RouterLink, i8.FooterComponent], styles: [".mf-holder[_ngcontent-%COMP%]{visibility:hidden}.loading-indicator[_ngcontent-%COMP%]{text-align:center}.authorized[_ngcontent-%COMP%]{color:red}.modal[_ngcontent-%COMP%]{position:fixed;top:0;right:0;bottom:0;left:0;z-index:1050;display:none;-webkit-overflow-scrolling:touch;outline:0}.modal-content[_ngcontent-%COMP%]{position:relative;background-color:#000;background-clip:padding-box;border:.5px solid #fff;color:red;font-size:.9vw}.modal-body[_ngcontent-%COMP%]{padding:40px 25px}.modal-dialog-centered[_ngcontent-%COMP%]{transition:opacity 15s;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;min-height:calc(100% - (.5rem * 2));position:absolute}.btn-holder[_ngcontent-%COMP%]{text-align:center}@media (min-width:768px){.modal-dialog[_ngcontent-%COMP%]{width:670px;margin:30px auto}}"] });
    return MenuComponent;
}());
export { MenuComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MenuComponent, [{
        type: Component,
        args: [{
                selector: 'app-menu',
                templateUrl: './menu.component.html',
                styleUrls: ['./menu.component.scss']
            }]
    }], function () { return [{ type: i1.Router }, { type: i2.UserService }, { type: i3.HttpClient }, { type: i4.AuthenticationService }, { type: i0.Renderer2 }]; }, { mainMenu: [{
            type: ViewChild,
            args: ['mainMenu', { static: false }]
        }] }); })();

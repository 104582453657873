import { Component, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
var _c0 = ["iframe"];
function ExternalComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    var ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r0.loadingMessage);
} }
var ExternalComponent = /** @class */ (function () {
    function ExternalComponent(route, renderer) {
        this.route = route;
        this.renderer = renderer;
        this.loadingIndicator = true;
        this.loadingMessage = 'Loading...';
        var id = this.route.snapshot.paramMap.get('id');
        var gpmsid = this.route.snapshot.paramMap.get('gpmsid');
        this.url = environment[id] + '?gpmsid=' + gpmsid;
    }
    ExternalComponent.prototype.ngAfterViewInit = function () {
        var main = this;
        this.iframe.nativeElement.addEventListener('load', function () {
            main.loadingIndicator = false;
            main.renderer.setStyle(main.iframe.nativeElement, 'visibility', 'visible');
            main.iframe.nativeElement.focus();
        });
        this.iframe.nativeElement.setAttribute('src', this.url);
    };
    ExternalComponent.ɵfac = function ExternalComponent_Factory(t) { return new (t || ExternalComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i0.Renderer2)); };
    ExternalComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExternalComponent, selectors: [["app-external"]], viewQuery: function ExternalComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(_c0, true);
        } if (rf & 2) {
            var _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.iframe = _t.first);
        } }, decls: 5, vars: 1, consts: [["class", "loading-indicator", 4, "ngIf"], ["id", "iframe", "sandbox", "allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-top-navigation allow-downloads"], ["iframe", ""], [1, "loading-indicator"]], template: function ExternalComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, ExternalComponent_div_0_Template, 2, 1, "div", 0);
            i0.ɵɵelementStart(1, "iframe", 1, 2);
            i0.ɵɵelementStart(3, "p");
            i0.ɵɵtext(4, "Please use a browser that support iframes.");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", ctx.loadingIndicator);
        } }, directives: [i2.NgIf], styles: ["[_nghost-%COMP%]{margin:0}iframe[_ngcontent-%COMP%]{height:100vh;width:100vw;border:0;visibility:hidden}.loading-indicator[_ngcontent-%COMP%]{text-align:center}"] });
    return ExternalComponent;
}());
export { ExternalComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExternalComponent, [{
        type: Component,
        args: [{
                selector: 'app-external',
                templateUrl: './external.component.html',
                styleUrls: ['./external.component.scss']
            }]
    }], function () { return [{ type: i1.ActivatedRoute }, { type: i0.Renderer2 }]; }, { iframe: [{
            type: ViewChild,
            args: ['iframe', { static: false }]
        }] }); })();

import { Component } from '@angular/core';
import * as i0 from "@angular/core";
var FooterComponent = /** @class */ (function () {
    function FooterComponent() {
    }
    FooterComponent.prototype.ngOnInit = function () {
    };
    FooterComponent.ɵfac = function FooterComponent_Factory(t) { return new (t || FooterComponent)(); };
    FooterComponent.ɵcmp = i0.ɵɵdefineComponent({ type: FooterComponent, selectors: [["app-footer"]], decls: 1, vars: 0, template: function FooterComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelement(0, "p");
        } }, styles: [""] });
    return FooterComponent;
}());
export { FooterComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FooterComponent, [{
        type: Component,
        args: [{
                selector: 'app-footer',
                templateUrl: './footer.component.html',
                styleUrls: ['./footer.component.scss']
            }]
    }], function () { return []; }, null); })();

import { Component } from '@angular/core';
import * as i0 from "@angular/core";
var NotFoundComponent = /** @class */ (function () {
    function NotFoundComponent() {
    }
    NotFoundComponent.prototype.ngOnInit = function () {
        console.log('Page not Found');
    };
    NotFoundComponent.ɵfac = function NotFoundComponent_Factory(t) { return new (t || NotFoundComponent)(); };
    NotFoundComponent.ɵcmp = i0.ɵɵdefineComponent({ type: NotFoundComponent, selectors: [["app-not-found"]], decls: 2, vars: 0, template: function NotFoundComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "p");
            i0.ɵɵtext(1, "You are not authorized to use this application.");
            i0.ɵɵelementEnd();
        } }, styles: ["p[_ngcontent-%COMP%]{text-align:center;line-height:90vh}"] });
    return NotFoundComponent;
}());
export { NotFoundComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NotFoundComponent, [{
        type: Component,
        args: [{
                selector: 'app-not-found',
                templateUrl: './not-found.component.html',
                styleUrls: ['./not-found.component.scss']
            }]
    }], function () { return []; }, null); })();

import { Component, HostListener } from '@angular/core';
import { interval } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./services/authentication.service";
import * as i2 from "@angular/router";
export var KEY_CODE;
(function (KEY_CODE) {
    KEY_CODE[KEY_CODE["ESC"] = 27] = "ESC";
    KEY_CODE[KEY_CODE["ENTER"] = 13] = "ENTER"; //ENTER KEY
})(KEY_CODE || (KEY_CODE = {}));
var AppComponent = /** @class */ (function () {
    function AppComponent(auth, router) {
        this.auth = auth;
        this.router = router;
        this.title = 'gpms-home';
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        //notify activity
        this.auth.callPing();
        var source = interval(5 * 60 * 1000);
        this.auth.sessionCheck = source.subscribe(function () {
            console.log('session check');
            if (!_this.auth.isAuthenticated()) {
                console.log('session timeout, refresing page to get token');
                location.reload();
            }
            setTimeout(function () { return _this.auth.callInactivityCheck(); }, 0);
        });
        this.auth.userActivityTimeout();
        this.auth.userInactive.subscribe(function () {
            console.log('notify user is active');
            _this.auth.callPing();
        });
    };
    AppComponent.prototype.onKeyDown = function (event) {
        window.onhelp = function () { return false; };
        this.auth.resetActivity();
    };
    AppComponent.prototype.onMouseMove = function (event) {
        this.auth.resetActivity();
    };
    AppComponent.ɵfac = function AppComponent_Factory(t) { return new (t || AppComponent)(i0.ɵɵdirectiveInject(i1.AuthenticationService), i0.ɵɵdirectiveInject(i2.Router)); };
    AppComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AppComponent, selectors: [["app-root"]], hostBindings: function AppComponent_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("keydown", function AppComponent_keydown_HostBindingHandler($event) { return ctx.onKeyDown($event); }, false, i0.ɵɵresolveWindow)("mousemove", function AppComponent_mousemove_HostBindingHandler($event) { return ctx.onMouseMove($event); }, false, i0.ɵɵresolveWindow);
        } }, decls: 2, vars: 0, template: function AppComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div");
            i0.ɵɵelement(1, "router-outlet");
            i0.ɵɵelementEnd();
        } }, directives: [i2.RouterOutlet], styles: [""] });
    return AppComponent;
}());
export { AppComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppComponent, [{
        type: Component,
        args: [{
                selector: 'app-root',
                templateUrl: './app.component.html',
                styleUrls: ['./app.component.scss']
            }]
    }], function () { return [{ type: i1.AuthenticationService }, { type: i2.Router }]; }, { onKeyDown: [{
            type: HostListener,
            args: ['window:keydown', ['$event']]
        }], onMouseMove: [{
            type: HostListener,
            args: ['window:mousemove', ['$event']]
        }] }); })();

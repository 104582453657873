import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./authentication.service";
import * as i3 from "./user.service";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(http, auth, usrService) {
        this.http = http;
        this.auth = auth;
        this.usrService = usrService;
    }
    AuthGuardService.prototype.canActivate = function (next, state) {
        var _this = this;
        var auth_service = environment.auth_service;
        var home = environment.home;
        var internalHome = environment.internal_home;
        /*
        if ((home !== internalHome) && (window.top.location.href.indexOf(internalHome) >= 0)) {
          console.log('Redirecting to proper URL.');
          window.top.location.href = home;
          return;
        }
        */
        var httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }),
            withCredentials: true
        };
        if (this.auth.isAuthenticated())
            return true;
        //skip api hit if token is valid
        var observable = this.http.get(auth_service + 'user', httpOptions).pipe(map(function (result) {
            if (result) {
                var userAuthentication = result.userAuthentication;
                var tokenValue = result.details.tokenValue;
                var details = userAuthentication.details;
                var identities = details.identities[0];
                _this.usrService.getUser().accessToken = tokenValue;
                _this.usrService.getUser().fname = identities.idpUserInfo.attributes.Firstname;
                _this.usrService.getUser().lname = identities.idpUserInfo.attributes.Lastname;
                _this.usrService.getUser().name = identities.idpUserInfo.attributes.name;
                _this.usrService.getUser().locale = identities.idpUserInfo.attributes.locale;
                _this.usrService.getUser().idpemail = identities.idpUserInfo.attributes.email;
                _this.usrService.getUser().user = userAuthentication.name;
                _this.usrService.getUser().email = details.email;
                return true;
            }
            else {
                _this.usrService.clearUser();
                console.log('not authenticated');
                return false;
            }
        }), catchError(function (err) {
            console.log(err);
            _this.usrService.clearUser();
            //window.open(auth_service + '?r=' + home,'_self');
            window.open(auth_service + '?r=' + window.location.href, '_self');
            return of(false);
        }));
        return observable;
    };
    AuthGuardService.ɵfac = function AuthGuardService_Factory(t) { return new (t || AuthGuardService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.UserService)); };
    AuthGuardService.ɵprov = i0.ɵɵdefineInjectable({ token: AuthGuardService, factory: AuthGuardService.ɵfac });
    return AuthGuardService;
}());
export { AuthGuardService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AuthGuardService, [{
        type: Injectable
    }], function () { return [{ type: i1.HttpClient }, { type: i2.AuthenticationService }, { type: i3.UserService }]; }, null); })();

import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
var HttpInterceptorService = /** @class */ (function () {
    function HttpInterceptorService() {
    }
    HttpInterceptorService.prototype.intercept = function (req, next) {
        var nextReq = req.clone({
            withCredentials: true
        });
        return next.handle(nextReq);
    };
    HttpInterceptorService.ɵfac = function HttpInterceptorService_Factory(t) { return new (t || HttpInterceptorService)(); };
    HttpInterceptorService.ɵprov = i0.ɵɵdefineInjectable({ token: HttpInterceptorService, factory: HttpInterceptorService.ɵfac });
    return HttpInterceptorService;
}());
export { HttpInterceptorService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(HttpInterceptorService, [{
        type: Injectable
    }], null, null); })();

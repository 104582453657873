import { Component } from '@angular/core';
import * as i0 from "@angular/core";
var BannerComponent = /** @class */ (function () {
    function BannerComponent() {
    }
    BannerComponent.prototype.ngOnInit = function () {
    };
    BannerComponent.ɵfac = function BannerComponent_Factory(t) { return new (t || BannerComponent)(); };
    BannerComponent.ɵcmp = i0.ɵɵdefineComponent({ type: BannerComponent, selectors: [["app-banner"]], decls: 15, vars: 0, consts: [[1, "col-lg-12", "no-padd", "main-title"]], template: function BannerComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵelement(1, "p");
            i0.ɵɵelement(2, "br");
            i0.ɵɵelementStart(3, "p");
            i0.ɵɵtext(4, "\u00A0\u2588\u2588\u2588\u2588\u2588\u2588\u2557\u2588\u2588\u2588\u2588\u2588\u2588\u2557\u00A0\u00A0\u2588\u2588\u2588\u2557\u00A0\u00A0\u00A0\u2588\u2588\u2588\u2557\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2557");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(5, "p");
            i0.ɵɵtext(6, "\u2588\u2588\u2554\u2550\u2550\u2550\u2550\u255D\u00A0\u2588\u2588\u2554\u2550\u2550\u2588\u2588\u2557\u2588\u2588\u2588\u2588\u2557\u00A0\u2588\u2588\u2588\u2588\u2551\u2588\u2588\u2554\u2550\u2550\u2550\u2550\u255D");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(7, "p");
            i0.ɵɵtext(8, "\u2588\u2588\u2551\u00A0\u00A0\u2588\u2588\u2588\u2557\u2588\u2588\u2588\u2588\u2588\u2588\u2554\u255D\u2588\u2588\u2554\u2588\u2588\u2588\u2588\u2554\u2588\u2588\u2551\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2557");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(9, "p");
            i0.ɵɵtext(10, "\u2588\u2588\u2551\u00A0\u00A0\u00A0\u2588\u2588\u2551\u2588\u2588\u2554\u2550\u2550\u2550\u255D\u00A0\u2588\u2588\u2551\u255A\u2588\u2588\u2554\u255D\u2588\u2588\u2551\u255A\u2550\u2550\u2550\u2550\u2588\u2588\u2551");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(11, "p");
            i0.ɵɵtext(12, "\u255A\u2588\u2588\u2588\u2588\u2588\u2588\u2554\u255D\u2588\u2588\u2551\u00A0\u00A0\u00A0\u00A0\u00A0\u2588\u2588\u2551\u00A0\u255A\u2550\u255D\u00A0\u2588\u2588\u2551\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2551");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(13, "p");
            i0.ɵɵtext(14, "\u00A0\u255A\u2550\u2550\u2550\u2550\u2550\u255D\u00A0\u255A\u2550\u255D\u00A0\u00A0\u00A0\u00A0\u00A0\u255A\u2550\u255D\u00A0\u00A0\u00A0\u00A0\u00A0\u255A\u2550\u255D\u255A\u2550\u2550\u2550\u2550\u2550\u2550\u255D");
            i0.ɵɵelementEnd();
            i0.ɵɵelementEnd();
        } }, styles: ["p[_ngcontent-%COMP%]{margin:0;padding:0;font-size:.95vw}"] });
    return BannerComponent;
}());
export { BannerComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(BannerComponent, [{
        type: Component,
        args: [{
                selector: 'app-banner',
                templateUrl: './banner.component.html',
                styleUrls: ['./banner.component.scss']
            }]
    }], function () { return []; }, null); })();

import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "src/app/services/user.service";
import * as i3 from "@angular/router";
var ExitComponent = /** @class */ (function () {
    function ExitComponent(http, usrService, router) {
        this.http = http;
        this.usrService = usrService;
        this.router = router;
        console.log('constructor AppExit');
    }
    ExitComponent.prototype.ngOnInit = function () {
        console.log('Exit - Logout SSO');
        this.usrService.clearUser();
        var auth_api = environment.auth_service;
        window.top.location.href = auth_api + 'logout';
    };
    ExitComponent.ɵfac = function ExitComponent_Factory(t) { return new (t || ExitComponent)(i0.ɵɵdirectiveInject(i1.HttpClient), i0.ɵɵdirectiveInject(i2.UserService), i0.ɵɵdirectiveInject(i3.Router)); };
    ExitComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ExitComponent, selectors: [["app-exit"]], decls: 2, vars: 0, consts: [[1, "col-lg-12", "no-padd", "main-title"]], template: function ExitComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵtext(1, " Logging out...\n");
            i0.ɵɵelementEnd();
        } }, styles: [""] });
    return ExitComponent;
}());
export { ExitComponent };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ExitComponent, [{
        type: Component,
        args: [{
                selector: 'app-exit',
                templateUrl: './exit.component.html',
                styleUrls: ['./exit.component.scss']
            }]
    }], function () { return [{ type: i1.HttpClient }, { type: i2.UserService }, { type: i3.Router }]; }, null); })();

import { Injectable } from '@angular/core';
import { User } from '../model/user.model';
import * as i0 from "@angular/core";
var UserService = /** @class */ (function () {
    function UserService() {
    }
    UserService.prototype.getUser = function () {
        if (!this.user) {
            this.user = new User();
        }
        return this.user;
    };
    UserService.prototype.clearUser = function () {
        this.user = null;
    };
    UserService.ɵfac = function UserService_Factory(t) { return new (t || UserService)(); };
    UserService.ɵprov = i0.ɵɵdefineInjectable({ token: UserService, factory: UserService.ɵfac });
    return UserService;
}());
export { UserService };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UserService, [{
        type: Injectable
    }], null, null); })();

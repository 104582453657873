import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';
import { ExternalComponent } from './components/gpms/external/external.component';
import { ExitComponent } from './components/exit/exit.component';
import { AuthGuardService } from './services/auth-guard.service';
import { BannerComponent } from './components/banner/banner.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var routes = [
    { path: '', component: MenuComponent, canActivate: [AuthGuardService] },
    { path: 'gpms/:id/:gpmsid', component: ExternalComponent, canActivate: [AuthGuardService] },
    { path: 'banner', component: BannerComponent, canActivate: [AuthGuardService] },
    { path: 'logout', component: ExitComponent, canActivate: [AuthGuardService] },
    { path: 'unauthorized', component: NotFoundComponent },
    { path: '**', redirectTo: 'unauthorized' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    AppRoutingModule.ɵmod = i0.ɵɵdefineNgModule({ type: AppRoutingModule });
    AppRoutingModule.ɵinj = i0.ɵɵdefineInjector({ factory: function AppRoutingModule_Factory(t) { return new (t || AppRoutingModule)(); }, imports: [[RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
            RouterModule] });
    return AppRoutingModule;
}());
export { AppRoutingModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AppRoutingModule, { imports: [i1.RouterModule], exports: [RouterModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppRoutingModule, [{
        type: NgModule,
        args: [{
                imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
                exports: [RouterModule]
            }]
    }], null, null); })();
